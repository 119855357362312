import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';

import './registerServiceWorker'

import store from './store'
import 'amfe-flexible'
// 引入video.js
import VueCoreVideoPlayer from 'vue-core-video-player'

import Videojs from 'video.js'



import 'video.js/dist/video-js.css'
import App from './App.vue'
import router from './router'

Vue.prototype.$video = Videojs


Vue.use(VueCoreVideoPlayer)
Vue.use(Vant);
// Vue.use(http)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  Vant,
  VueCoreVideoPlayer,
  render: h => h(App)
}).$mount('#app')
