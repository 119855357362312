import Vue from 'vue'
import VueRouter from 'vue-router'
import clientRouter from './child/clientPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'clienthome',
    component: () => import('../views/clientPage/clientHome.vue'),
    redirect: "/index",
    children: [
      // 客户端首页
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/clientPage/childPage/index.vue')
      },
      // 运价页面
      {
        path: '/fare',
        name: 'fare',
        component: () => import('../views/clientPage/childPage/fare.vue')
      },
      // 资讯页面
      {
        path: '/information',
        name: 'information',
        component: () => import('../views/clientPage/childPage/information.vue')
      },
      // 我的页面
      {
        path: '/mine',
        name: 'mine',
        meta: {
          isLogin: true,
        },
        component: () => import('../views/clientPage/childPage/mine.vue')
      }]
  },

  // 咨询详情
  {
    path: '/zxxq',
    name: 'zxxq',
    component: () => import('../views/clientPage/zxxq.vue')
  },

  // 内部页面

  {
    path: '/',
    name: 'serverhome',
    component: () => import('../views/serverPage/serverHome.vue'),
    redirect: "/indexs",
    children: [
      // 客户端首页
      {
        path: '/indexs',
        name: 'indexs',
        component: () => import('../views/serverPage/childPage/indexs.vue')
      },
      // 运价页面
      {
        path: '/fares',
        name: 'fares',
        component: () => import('../views/serverPage/childPage/fares.vue'),

      },
      // 服务页面
      {
        path: '/serves',
        name: 'serves',
        component: () => import('../views/serverPage/childPage/serves.vue'),

      },
      // 资讯页面
      {
        path: '/informations',
        name: 'informations',
        component: () => import('../views/serverPage/childPage/informations.vue')
      },
      // 我的页面
      {
        path: '/mines',
        name: 'mines',
        component: () => import('../views/serverPage/childPage/mines.vue')
      }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/userLogin',
    name: 'userLogin',
    component: () => import('../views/userLogin.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/reg.vue')
  },
  {
    path: '/setPage',
    name: 'setPage',
    component: () => import('../views/setPage.vue')
  },
  {
    path: '/xgPsd',
    name: 'xgPsd',
    component: () => import('../views/xgPsd.vue')
  },
  // 忘记密码
  {
    path: '/wjPas',
    name: 'wjPas',
    component: () => import('../views/wjPas.vue')
  },
  ,// 我的订单页面
  {
    path: '/allDd',
    name: 'allDd',
    component: () => import('../views/allDd.vue')
  },
  // 我的账单页面
  {
    path: '/allZd',
    name: 'allZd',
    component: () => import('../views/allZd.vue')
  },
  // 运价查询页面
  {
    path: '/yjPage',
    name: 'yjPage',
    component: () => import('../views/yjPage.vue')
  },
  // 运价查询页面
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('../views/userInfo.vue')
  },
  // 客服页面
  {
    path: '/servicePage',
    name: 'servicePage',
    component: () => import('../views/servicePage.vue')
  },
  // 消息中心页面
  {
    path: '/mesPage',
    name: 'mesPage',
    component: () => import('../views/mesPage.vue')
  },
  // 海安
  {
    path: '/haian',
    name: 'haian',
    component: () => import('../views/haian.vue')
  },
  // 海泰
  {
    path: '/haitai',
    name: 'haitai',
    component: () => import('../views/haitai.vue')
  },
  // 宁波
  {
    path: '/nibo',
    name: 'nibo',
    component: () => import('../views/nibo.vue')
  },
  // 帮助中心
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/video.vue')
  },
  // 订单详情
  {
    path: '/ddanXq',
    name: 'ddanXq',
    component: () => import('../views/ddanXq.vue')
  },
  // 账单详情
  {
    path: '/zdanXq',
    name: 'zdanXq',
    component: () => import('../views/zdanXq.vue')
  },
  // 运价查询结果
  {
    path: '/yjSearchPage',
    name: 'yjSearchPage',
    component: () => import('../views/yjSearchPage.vue')
  },
  // 消息中心详情
  {
    path: '/mesxq',
    name: 'mesxq',
    component: () => import('../views/mesxq.vue')
  },
  // 天津分公司
  {
    path: '/tjGWBN',
    name: 'tjGWBN',
    component: () => import('../views/tjGWBN')
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})


// 路由守卫
router.beforeEach((to, from, next) => {
  // 首先判断当前访问的路由是否需要登录状态访问两种方式
  console.log("当前要跳转到的路由", to.path);
  console.log("是否跳转", to);
  if (to.meta.isLogin) {
    console.log("进入判断登录逻辑");
    if (localStorage.getItem("user")) {
      // 登录了   
      next();
    } else {
      // 跳转到登录
      router.push("/login").catch((err) => { });
    }
  } else {
    //不需要访问就能登录的
    console.log("不进入判断登录逻辑");
    next();
  }
});

export default router




