<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  data() {
    return {
     
    };
  },
 
}
</script>

<style lang="less">
html,
body,
#app {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
